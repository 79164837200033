import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '../../interface/user';
import { CognitoService } from '../../services/aws-cognito/cognito.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {ToastrService} from "ngx-toastr";
import { FormControl, FormGroup } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('autoOpenModalBtn') autoOpenModalBtn: ElementRef;

  user : IUser;
  usernameForForgetPassword: string;
  code: string;
  newPassword: string;
  confirmPassword: string;
  modalRef: BsModalRef;
  step: number = 1; // Track the reset password steps
  resetPasswordModal: BsModalRef;
  currentPassword : string = '';
  newPass : string;
  confirmPass : string;
  name: string;

  constructor(private router : Router, private cognito : CognitoService, private modalService: BsModalService, private toastrService : ToastrService){
    this.user = {} as IUser;

  }


  ngOnInit(){
    this.login()
  }

  openModal(template: TemplateRef<any>): void {
    if (this.cognito.isFirstTimeLogin()) {
      this.step = 3;
      this.resetPasswordModal = this.modalService.show(template);
    }else{
      this.modalRef = this.modalService.show(template);
    }
  }

  userSubmit() {
    this.cognito.forgetPassword(this.usernameForForgetPassword)
      .then(() => {
        // Move to the next step (entering code and new password)
        this.step = 2;
      })
      .catch((error) => {
        // Handle the error or notify the user
        this.toastrService.warning('Username is invalid.');
      });

  }

  changePassword() {
    const user: IUser = {
      username: this.usernameForForgetPassword,
      code: this.code,
      password: this.newPassword,
      showPassword: false,
      newPassword : '',
      name: ''
    };

    if (this.confirmPassword !== this.newPassword) {
      this.toastrService.warning('Password does not match.');
      return;
    }

    this.cognito.forgetPasswordSubmit(user)
      .then(() => {
        // Password changed successfully
        this.toastrService.success('Password changed successfully.');
        this.closeResetPasswordModal();
      })
      .catch((error) => {
        // Handle the error or notify the user
        this.toastrService.warning(error);

      });
  }

  changePasswordNewUser() {
    if (this.confirmPass !== this.newPass) {
      this.toastrService.warning('Password does not match.');
      return;
    }
    this.cognito.changePassword(this.newPass, this.name)
      .then(() => {
        // Password changed successfully
        this.toastrService.success('Password changed successfully.');
        this.modalService.hide();
      })
      .catch((error) => {
        // Handle the error or notify the user
        this.toastrService.warning(error);
      });
  }

  closeResetPasswordModal(){
    this.modalRef.hide();
  }

  login() {
    if (this.user && this.user.username && this.user.password) {
      this.cognito.signIn(this.user).then(() => {
        if (this.cognito.isFirstTimeLogin()) {
          // Open the password change modal
          this.autoOpenModalBtn.nativeElement.click();
        } else {
          this.router.navigate(['./dashboard']);
        }
      }).catch((error) => {
        alert(error.message);
      });
    }
  }
}
