<div class="table" >
  <ag-grid-angular
    class="ag-theme-alpine h-100 w-60"
    [gridOptions]="gridOptions"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowSelection]="'single'"
    (gridReady)="onGridReady($event)"
    (rowSelected)="onRowSelected()"
    [domLayout]="'autoHeight'"
  >
  </ag-grid-angular>
</div>
