import {Component} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {PackageSearchService} from "../../../services/package-search/package-search.service";

@Component({
  selector: 'app-more-details-button-cell-renderer',
  templateUrl: './more-details-button-cell-renderer.component.html',
  styleUrls: ['./more-details-button-cell-renderer.component.scss']
})
export class MoreDetailsButtonCellRendererComponent implements ICellRendererAngularComp {
  params: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private api: PackageSearchService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onButtonClick(): void {
    const navigationExtras: NavigationExtras = {
      relativeTo: this.activatedRoute,
      queryParams: {
        packageID: this.params.data.packageID
      },
      state: {
        packageID: this.params.data.packageID,
        packageType: this.params.data.packageType
      }
    };

    this.router.navigate(['/package-search/details'], navigationExtras);
  }


}
