<div class="container mt-3">
  <h3 class="ml-6">Package Search</h3>
</div>

<div class="row col-12">
  <div class="container mt-3 card" style="width: 96%">
    <div class="row">
      <div class="col-sm-12 col-xxl-6 col-xl-12 col-md-12">
        <div class="input-group mb-3">
          <input type="text" id="barcode" name="Barcode" class="form-control search" placeholder="Search Barcode"
                 [(ngModel)]="searchTerm"/>
          <img [hidden]="disableButton" (click)="onSearch()" src="assets/img/icon_search_barcode.svg" alt="search-icon" width="40px"/>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 col-md-6 col-xxl-2" style="padding-right: 20px;">
        <input type="text"
               placeholder="Daterangepicker"
               class="form-control btn-dropdown"
               style="text-align: center; margin-bottom: 1.5rem; width: 100%"
               [bsConfig]="bsConfig"
               [(ngModel)]="datePickerValue"
               #rangePicker="bsDaterangepicker"
               (onShown)="onDateRangePickerShow()"
               bsDaterangepicker>
      </div>

      <div class="col-sm-12 col-lg-6 col-md-6 col-xxl-2" style="padding-right: 35px;">
        <div class="dropdown mb-3">
          <button style="width: 100%" class="btn dropdown-toggle btn-dropdown" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            {{selectedShipmentTypeName !== null ? selectedShipmentTypeName : 'Shipment Type'}}
          </button>
          <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu">
            <li *ngFor="let shipmentType of shipmentTypes" style="width: 16rem">
              <a (click)="onSelectedShipmentType($event)" class="dropdown-item pointer">{{shipmentType.label}}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-sm-12 col-xxl-2 col-xl-6 col-lg-6 col-md-6" style="padding-right: 35px; min-width: 190px">
        <div class="dropdown">
          <button style="width: 100%" class="btn dropdown-toggle btn-dropdown" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            {{selectedDeliveryPartner !== null ? selectedDeliveryPartner : 'Delivery Partner'}}
          </button>
          <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu">
            <li *ngFor="let pickUpPartner of dropdownPartner$ | async" style="width: 16rem">
              <a (click)="onSelectedDeliveryPartner($event)" class="dropdown-item pointer">{{pickUpPartner.partner_id}}
                - {{pickUpPartner.name}}</a>
            </li>
          </ul>
        </div>
      </div>

    </div>


    <div class="row justify-content-end">
      <div class="col-xxl-2 col-xl-12 col-sm-12 col-lg-12 col-md-12 mb-2">
        <div class="container mt-2 align-items-center">
          <div class="form-check form-check-inline">
            <input [disabled]="isColliChecked" (change)="checkedInputFields($event)" class="form-check-input"
                   type="checkbox" id="palette" style="width: 20px; height: 20px; margin-right: 20px">
            <label class="form-check-label" for="palette" style="display: flex; align-items: center;">
              <img src="assets/img/Express_Palette.svg" alt="palette-icon" width="35px" title="Palette" />
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input [disabled]="isPaletteChecked" (change)="checkedInputFields($event)" class="form-check-input"
                   type="checkbox" id="colli" style="width: 20px; height: 20px; margin-right: 20px">
            <label class="form-check-label" for="colli" style="display: flex; align-items: center;">
              <img src="assets/img/Express_Colli.svg"  alt="colli-icon" width="35px" title="Colli"/>
            </label>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-xl-6 col-xxl-2 col-lg-6 mt-2" style="padding-right: 35px;">
        <div class="mb-3 dropdown ">
          <button style="width: 100%;" class=" btn dropdown-toggle btn-dropdown" type="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
            {{selectedCountry !== null ? selectedCountry : 'Select Country'}}
          </button>
          <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu">
            <li *ngFor="let country of dropdownCountries$ | async" style="width: 16rem">
              <a (click)="onSelectedCountry($event)" class="dropdown-item pointer">{{country}}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-xl-6 col-xxl-2 col-lg-6 mt-2" style="padding-right: 20px;">
        <input
          [(ngModel)]="zipcodeFrom"
                type="number"
                placeholder="Zipcode from"
                class="form-control btn-dropdown"
                style="text-align: center; margin-bottom: 1.5rem; width: 100%">
      </div>

      <div class="col-sm-12 col-md-6 col-xl-6 col-xxl-2 col-lg-6 mt-2" style="padding-right: 20px;">
        <input
                [(ngModel)]="zipcodeTo"
                type="number"
                placeholder="Zipcode to"
                class="form-control btn-dropdown"
                style="text-align: center; margin-bottom: 1.5rem; width: 100%">
      </div>

      <div class="col-sm-12 col-xxl-2 col-xl-6 col-lg-6 col-md-6 mt-2" style="padding-right: 35px; min-width: 190px">
        <div class="dropdown  mb-3">
          <button style="width: 100%" class="btn dropdown-toggle btn-dropdown" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            {{selectedPickupPartner !== null ? selectedPickupPartner : 'Pick-up Partner'}}
          </button>
          <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu">
            <li *ngFor="let pickUpPartner of dropdownPartner$ | async" style="width: 16rem">
              <a (click)="onSelectedPickupPartner($event)" class="dropdown-item pointer">{{pickUpPartner.partner_id}}
                - {{pickUpPartner.name}}</a>
            </li>
          </ul>
        </div>
      </div>

<!--      <div class="col-sm-12 col-xxl-3 col-xl-6 col-lg-6 col-md-6 mt-2" style="padding-right: 35px; min-width: 190px">-->
<!--        <div class="dropdown">-->
<!--          <button style="width: 100%" class="btn dropdown-toggle btn-dropdown" type="button" data-bs-toggle="dropdown"-->
<!--                  aria-expanded="false">-->
<!--            {{selectedDeliveryPartner !== null ? selectedDeliveryPartner : 'Delivery Partner'}}-->
<!--          </button>-->
<!--          <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu">-->
<!--            <li *ngFor="let pickUpPartner of dropdownPartner$ | async" style="width: 24rem">-->
<!--              <a (click)="onSelectedDeliveryPartner($event)" class="dropdown-item pointer">{{pickUpPartner.partner_id}}-->
<!--                - {{pickUpPartner.name}}</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
      <div>
        <button class="btn btn-primary reset-filter" style="background-color: #2E628F" (click)="resetFilter()">Reset filter</button>
      </div>
    </div>
  </div>



  <!-- ag grid -->
  <div class="table">
    <ag-grid-angular
      class="ag-theme-alpine table-size h-100 w-60"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowSelection]="'single'"
      (gridReady)="onGridReady($event)"
      (rowSelected)="onRowSelected()"
    >
    </ag-grid-angular>
  </div>

  <app-loader *ngIf="isLoading"></app-loader>


</div>

