<div class="custom-css">

  <p style="font-size: 80px; font-weight: bold;">EuroConnect</p>
  <h1>Hello & Welcome {{sessionStorage.getItem('loggedUserName')}}</h1>

  <div>
    <div routerLink="/package-search" class="card card-style m-top">
      <h4 class="card-text-style">Package Search</h4>
    </div>
    <div routerLink="/ticketsystem" class="card card-style mt-4">
      <h4 class="card-text-style">Ticketsystem </h4>
    </div>
  </div>
</div>
