<accordion-group #one class="mt-3 custom-panel">
  <span class="header-container" accordion-heading>Shipment Chain</span>
  <span class="icon-container" accordion-heading>
    <p *ngIf="one.isOpen"><i class="fa-solid fa-caret-up"></i></p>
    <p *ngIf="!one.isOpen"><i class="fa-solid fa-caret-down"></i></p>
  </span>
  <ag-grid-angular
        class="ag-theme-alpine width: 100% height: 100px"
        [gridOptions]="gridOptions"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowSelection]="'single'"
        (rowDoubleClicked)="rowDoubleClicked($event)"
        (gridReady)="onGridReady($event)"
        [domLayout]="'autoHeight'"
  ></ag-grid-angular>

</accordion-group>
