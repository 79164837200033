import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../../models/user-maintenance/user-maintenance.model";

@Injectable({
  providedIn: 'root'
})
export class UserMaintenanceService {
  private headers: HttpHeaders = new HttpHeaders();
  private url: string = environment.aws_url;

  private refreshTable = new BehaviorSubject(false);
  refreshUserTableObserv = this.refreshTable.asObservable();

  refreshUserTable(refresh: boolean) {
    this.refreshTable.next(refresh);
  }

  constructor(private http: HttpClient) { }

  private getHttpHeaders(): void {
    this.headers = this.headers.set("Authorization", `Bearer ${localStorage.getItem('id_token')}`);
  }

  public getPartners(): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.get<HttpResponse<any>>(this.url + '/groups', {headers: this.headers, observe: 'response'});
  }

  public createUser(requestBody: User): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.post<HttpResponse<any>>(this.url + '/user', requestBody, {headers: this.headers, observe: 'response'});
  }

  public getAllUsers(): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.get<HttpResponse<any>>(this.url + '/users', {headers: this.headers, observe: 'response'});
  }

  public addUserToGroup(requestBody: any): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.post<HttpResponse<any>>(this.url + '/groups/user', requestBody, {headers: this.headers, observe: 'response'});
  }

}
