import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ColDef, GridApi, GridOptions, GridReadyEvent, PaginationNumberFormatterParams,} from 'ag-grid-community';
import {
  AddUserToPartnerButtonCellRendererComponent
} from './add-user-to-partner-button-cell-renderer/add-user-to-partner-button-cell-renderer.component';
import {UserMaintenanceService} from "../../services/user-maintenance/user-maintenance.service";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {passwordValidator} from "../../validators/password-validator";

@Component({
  selector: 'app-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.scss']
})
export class UserMaintenanceComponent implements OnInit {
  openUserModel: BsModalRef;

  public gridApi: GridApi;
  public gridOptions: GridOptions;
  public defaultColDef;
  public columnDefs: ColDef[];
  public paginationPageSize = 15;
  public paginationPageSizeSelector: number[] | boolean = [15, 50, 100];
  public paginationNumberFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  gridColumnApi: any;
  showPassword: boolean = false;

  partners: any[] = [];

  selectedItems: string[] = [];

  createUserForm: FormGroup = this.fb.group({
    username: ['', Validators.required],
    name: ['', Validators.required],
    email: ['', Validators.required],
    temporary_password: ['', [Validators.required, passwordValidator()]],
    groups: new FormArray([])
  });

  constructor(
    private modalService: BsModalService,
    private userMaintenanceService: UserMaintenanceService,
    private fb: FormBuilder,
    private toastrService: ToastrService) {

    this.gridOptions = {
      columnDefs: this.columnDefs,
      paginationPageSize: this.paginationPageSize,
      paginationNumberFormatter: this.paginationNumberFormatter,
      domLayout: 'autoHeight',
      pagination: true,
      enableCellTextSelection: true
    }

    this.defaultColDef = {
      flex: 1,
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.columnDefs = [
      {
        headerName: 'User Name',
        field: "Username",
      },
      {
        headerName: 'E-Mail Address',
        field: "email",
        valueGetter: (params) => this.getEmail(params.data)
      },
      {
        headerName: 'Partner',
        field: "Groups",
      },
      {
        headerName: '',
        cellRenderer: AddUserToPartnerButtonCellRendererComponent
      }
    ];
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.getTableData();
  }

  getTableData(): void {
    this.userMaintenanceService.getAllUsers().subscribe((users) => {
      if (users.status == 200) {
        this.gridApi.setRowData(users.body);
      }
    });
  }

  get groups() {
    return this.createUserForm.controls["groups"] as FormArray;
  }

  ngOnInit(): void {
    this.userMaintenanceService.getPartners().subscribe((partners) => {
      if (partners.status == 200) {
        this.partners = partners.body;
      }
    });

    this.userMaintenanceService.refreshUserTableObserv.subscribe((refresh) => {
      if (refresh) {
        this.getTableData();
        this.userMaintenanceService.refreshUserTable(false);
      }
    });
  }

  addNewUser(template: TemplateRef<any>) {
    this.openUserModel = this.modalService.show(template, {class: 'modal-lg modal-dialog-centered',});
  }

  addUser() {
    this.openUserModel.hide();
    this.selectedItems.map((partner) => {
      this.groups.push(this.fb.control(partner));
    });

    this.userMaintenanceService.createUser(this.createUserForm.value).subscribe((response) => {
      if (response.status == 200) {
        this.toastrService.success('User ' + response.body['User'].Username + ' created successfully');
        this.getTableData();
      }
    }, error => {
      console.log(error)
      this.toastrService.error(error.error, 'Error');
    });
  }

  getEmail(data) {
    const email = data.Attributes.find((attribute) => attribute.Name === 'email');
    return email?.Value;
  }

  closeModal() {
    this.modalService.hide();
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  handleCB(event: any): void {
    const checkbox = event.target;
    if (checkbox.checked) {
      this.selectedItems.push(checkbox.value);
    } else {
      this.selectedItems = this.selectedItems.filter((item) => item !== checkbox.value);
    }
  }


}
