import { Component, Input, OnInit } from '@angular/core';
import { AgChartOptions, } from 'ag-charts-community';
import { PackageSearchService } from 'src/app/services/package-search/package-search.service';

@Component({
  selector: 'app-temperature-chain',
  templateUrl: './temperature-chain.component.html',
  styleUrls: ['./temperature-chain.component.scss']
})
export class TemperatureChainComponent implements OnInit{

  public options: AgChartOptions;
  isCollapsed: boolean = false;
  show: boolean = false;
  tempData : any;
  tempList : any[] = [];
  minTemp : any;
  maxTemp : any;


  @Input() set tempPackageId(packageId) {
    if(packageId) {
      packageId.subscribe((id) => {
        this.api.getTempData(id).subscribe((res)=>{
          this.tempData = Object.entries(res);
          this.processTemperatureData();
          this.updateChartOptions();
          this.generateTempData()
            })
      })
    }
  }

 constructor(private api : PackageSearchService) {}

 ngOnInit(): void {
    this.updateChartOptions();
}

updateChartOptions(): void {
  this.options = {
  data: this.getTemperatureData(),

  title: {
      text: 'Temperature',
  },
  series: [
      {
          xKey: 'time',
          yKey: 'sensor',
          // yName: 'PartnerId',
          // connectMissingData: false,
          tooltip: {
            enabled: true,
            renderer: (params) => {
              const partner_id = params.datum.partner_id;
              const {datum} = params;
              const formattedTime = datum.time.toLocaleString();
              return {
                content: `${formattedTime} <br> <b>${datum.sensor}°C</b> <br> <b>Partner ID: ${partner_id}</b>`,
              };
            },
          },
      },
  ],


  axes: [
          {
              type: 'time',
              position: 'bottom',
             nice: true,
          },
          {
              type: 'number',
              position: 'left',
              label: {
                  format: '#{.1f} °C',
              },
          },
      ],
};
}


  generateTempData() {
  }


processTemperatureData(): void {
  this.tempList = this.tempData.map(entry => ({

    time: new Date(entry[0]),
    sensor: +entry[1].temperature,
    partner_id: entry[1].partner_id
  }));

  // Sort the data by time
  this.tempList.sort((a, b) => a.time.getTime() - b.time.getTime());

  // Handle missing data points
  const gapThreshold = 60 * 60 * 1000; // 60 minutes in milliseconds

  // for (let i = 0; i < this.tempList.length - 1; i++) {
  //   const currentEntry = this.tempList[i];
  //   const nextEntry = this.tempList[i + 1];
  //   const timeDifference = nextEntry.time.getTime() - currentEntry.time.getTime();

    // Check for a gap
    // if (timeDifference >= gapThreshold) {
    //   this.tempList[i + 1].sensor = null;
    // }
  // }

  // Calculate min and max temperature based on processed data
  let minTemp = Infinity;
  let maxTemp = -Infinity;

  this.tempList.map((tempData) => {
    minTemp = Math.min(minTemp, tempData.sensor);
    maxTemp = Math.max(maxTemp, tempData.sensor);
  })

  // Set the min and max temperature
  this.minTemp = minTemp;
  this.maxTemp = maxTemp;
}

getTemperatureData(): any[] {
  return this.tempList;
}


 toggleCollapse(): void {
   this.isCollapsed = !this.isCollapsed;
   this.show = !this.show
 }


 mockData = [
   {
     "time": "Mon Dec 11 2023 15:00:12 GMT+0100 (Mitteleuropäische Normalzeit)",
     "sensor": 16,
     "partner_id": "0001"
   },
   {
     "time": "Mon Dec 11 2023 15:30:12 GMT+0100 (Mitteleuropäische Normalzeit)",
     "sensor": 20,
     "partner_id": "0001"
   },
   {
     "time": "Mon Dec 11 2023 16:00:12 GMT+0100 (Mitteleuropäische Normalzeit)",
     "sensor": 45,
     "partner_id": "0001"
   },
   {
     "time": "Mon Dec 11 2023 16:45:12 GMT+0100 (Mitteleuropäische Normalzeit)",
     "sensor": 10,
     "partner_id": "0001"
   },
   {
     "time": "Mon Dec 11 2023 17:15:12 GMT+0100 (Mitteleuropäische Normalzeit)",
     "sensor": -10,
     "partner_id": "0002"
   },
   {
     "time": "Mon Dec 11 2023 19:00:12 GMT+0100 (Mitteleuropäische Normalzeit)",
     "sensor": 30,
     "partner_id": "0003"
   }
 ]

}
