import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './views/default-layout/default-layout.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { PackageSearchComponent } from './views/package-search/package-search.component';
import { DetailsComponent } from './views/details/details.component';
import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { UserMaintenanceComponent } from './views/user-maintenance/user-maintenance.component';

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'ticketsystem',
        loadChildren: () =>
          import('./modules/ticketsystem/ticketsystem.module').then(
            (m) => m.TicketsystemModule
          ),
        data: {

        },
        canActivate: [AuthGuard]
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate : [AuthGuard]
      },
      {
        path: 'package-search',
        component: PackageSearchComponent,
        canActivate : [AuthGuard]
      },
      {
        path: 'package-search/details',
        component: DetailsComponent,
        canActivate : [AuthGuard]
      },
      {
        path: 'user-maintenance',
        component: UserMaintenanceComponent,
        canActivate : [AuthGuard]
      },
    ],
  },
];



@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
