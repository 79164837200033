import {Component} from '@angular/core';
import {DialogService} from "../../../modules/dialog/services/dialog.service";
import {UserMaintenanceService} from "../../../services/user-maintenance/user-maintenance.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogConfig} from "../../../modules/dialog/models/dialog-config.model";
import {DialogCloseResultType} from "../../../modules/dialog/models/dialog-close-result.enum";
import {DialogRef} from "../../../modules/dialog/dialog-ref";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-add-user-to-partner-modal',
  templateUrl: './add-user-to-partner-modal.component.html',
  styleUrls: ['./add-user-to-partner-modal.component.scss']
})
export class AddUserToPartnerModalComponent {
  public params;
  public partners = [];
  public showSubmitButton: boolean = false;

  addUserToPartnerForm: FormGroup = this.fb.group({
    username: ['', Validators.required],
    group_name: ['', Validators.required]
  });

  constructor(
    private dialogService: DialogService,
    private userMaintenanceService: UserMaintenanceService,
    private fb: FormBuilder,
    private dialogRef: DialogRef,
    private dialogConfig: DialogConfig,
    private toastrService: ToastrService) {

    this.userMaintenanceService.getPartners().subscribe((partners) => {
      if (partners.status == 200) {
        this.partners = partners.body;
      }
    });

    if (this.dialogConfig.data) {
      this.addUserToPartnerForm.patchValue({
        username: this.dialogConfig.data.Username,
      });
    }
  }

  addPartner() {
    this.userMaintenanceService.addUserToGroup(this.addUserToPartnerForm.value).subscribe((response) => {
     if(response.status == 200) {
       this.userMaintenanceService.refreshUserTable(true);
       this.toastrService.success('Partner was successfully added');
     } else {
        this.toastrService.error('An unknown error has occurred', 'Error')
     }
    });

    this.dialogRef.close({
      result: DialogCloseResultType.CLOSEOK,
    });
  }

  onSelectedPartner(event) {
    this.addUserToPartnerForm.patchValue({group_name: event.target.value});
    this.showSubmitButton = true;
  }
}
