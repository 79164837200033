import {Component, Input, OnInit} from '@angular/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {PackageDetailReferences} from "../../../models/references/reference.model";

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit {

  public gridApi: GridApi;
  public gridOptions: GridOptions;
  private gridColumnApi: any;
  public selectedRow;
  public defaultColDef;
  public columnDefs: ColDef[];


  public references: PackageDetailReferences[] = [{
    type: '',
    function: '',
    value: ''
  }];

  @Input() set packageDetail(detail) {
    for (let i = 0; i < 1; i++) {
      this.references = detail[i].references;
    };
  }

  constructor() {

    this.gridOptions = {
      columnDefs: this.columnDefs,
      enableCellTextSelection: true
    }

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      filter: true,
    };

    this.columnDefs = [
      {
        headerName: 'Reference Type',
        field: 'type',
      },
      {
        headerName: 'Reference Function',
        field: 'function',
      },
      {
        headerName: 'Reference Value',
        field: 'value',
      },
    ];
  }

  ngOnInit(): void {

  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setRowData(this.references);
  }

  onRowSelected() {

  }
}
