import {Component, Input} from '@angular/core';
import {PackageDetailServices} from "../../../models/services/services.model";

@Component({
  selector: 'app-pickup-service',
  templateUrl: './pickup-service.component.html',
  styleUrls: ['./pickup-service.component.scss']
})
export class PickupServiceComponent {

  public packageDetailServices: PackageDetailServices = {
    date: '',
    sms: '',
    code: '',
    email: ''
  }

  @Input() set packageDetail(detail) {
    for (let i = 0; i < 1; i++) {
      this.packageDetailServices = detail[i].services[i];
    }
  }

}
