<accordion-group #one class="mt-3 custom-panel">
  <span class="header-container" accordion-heading>References</span>
  <span class="icon-container" accordion-heading>
    <p *ngIf="one.isOpen"><i class="fa-solid fa-caret-up"></i></p>
    <p *ngIf="!one.isOpen"><i class="fa-solid fa-caret-down"></i></p>
  </span>

  <div class="table" >
    <ag-grid-angular
      class="ag-theme-alpine width: 100% height:100px"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowSelection]="'single'"
      (gridReady)="onGridReady($event)"
      (rowSelected)="onRowSelected()"
      [domLayout]="'autoHeight'"
    >
    </ag-grid-angular>
  </div>

</accordion-group>
