import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{
  isLoading : boolean =  true;
  protected readonly sessionStorage = sessionStorage;
  constructor(){}

  ngOnInit(): void {
     setTimeout(() => {
        this.isLoading = false;
      }, 1000);
  }

}
