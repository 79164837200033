import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PackageSearchService} from "../../../services/package-search/package-search.service";
import {ColDef, GridApi, GridOptions, GridReadyEvent} from "ag-grid-community";

@Component({
  selector: 'app-status-event',
  templateUrl: './status-event.component.html',
  styleUrls: ['./status-event.component.scss']
})
export class StatusEventComponent implements OnInit {

  private packageId: number;
  @Input() packageDetails: any;

  @Input() set packageDetail(detail) {
    for (let i = 0; i < 1; i++) {    
      this.packageId = detail[i].packageID;     
    }
  }

  public gridApi: GridApi;
  public gridOptions: GridOptions;
  private gridColumnApi: any;
  public defaultColDef;
  public columnDefs: ColDef[];
  eventText: string = '';
  eventCode: string = '';

  constructor(private packageSearchService: PackageSearchService) {

    this.gridOptions = {
      columnDefs: this.columnDefs,
      enableCellTextSelection: true
    }

    this.defaultColDef = {
      flex: 1,
      resizable: true,
      sortable: true,
      filter: true,
    };

    this.columnDefs = [
      {
        headerName: 'Event Code',
        field: 'eventcode',
        valueGetter: params => {
          const eventCode = params.data.eventcode;
          return eventCode + this.getEventText(eventCode);
        },
      },
      {
        headerName: 'Sub-Event Code',
        field: 'subeventCode',
      },
      {
        headerName: 'Eventtime',
        field: 'timestamp',
      },
      {
        headerName: 'Event Text',
        field: 'eventText',
      },
      {
        headerName: 'Event Location',
        field: 'eventLocation',
      },
      {
        headerName: 'Event Room Location',
        field: 'eventRoomID',
      },
      {
        headerName: 'Transport Unit',
        field: 'transportUnit',
      },
      {
        headerName: 'Partner',
        field: 'eventPartnerID',
      },
      {
        headerName: 'Country',
        field: 'country',
      },
    ];

  }

  ngOnInit() {
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.packageSearchService.getPackageEvents(this.packageId).subscribe((packageEvent) => {
      console.log(packageEvent)
      if (packageEvent.status == 200) {
        this.gridApi.setRowData(packageEvent.body);
      }
    });
  }

  private getEventText(eventCode: string): string {
    switch (eventCode) {
      case '10':
        return ' - Successful delivery';
      case '11':
        return ' - Deposit by agreement';
      case '12':
        return ' - Pick-Up successful';
      case '13':
        return ' - Self Pick-Up';
      case '20':
        return ' - Loss of goods';
      case '21':
        return ' - Broken goods';
      case '22':
        return ' - Return shipment';
      case '23':
        return ' - Refusal of acceptance';
      case '24':
        return ' - Vacation';
      case '25':
        return ' - Wrong Address';
      case '50':
        return ' - Out for pickup scan';
      case '51':
        return ' - Scan at the inbound gateway';
      case '52':
        return ' - Scan into temperature controlled room';
      case '53':
        return ' - Loading Scan';
      case '54':
        return ' - Scan at the outbound gateway';
      case '55':
        return ' - Out-for-delivery scan';
      case '56':
        return ' - Information on Delivery';
      case '60':
        return ' - Transport Unit Connection';
      default:
        return ' - Unknown Event';
    }
  }



}
