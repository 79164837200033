import {Component, Input, OnInit} from '@angular/core';
import {DialogService} from "../../../modules/dialog/services/dialog.service";
import {ColDef, GridApi, GridOptions, GridReadyEvent} from "ag-grid-community";
import {
  RadioactiveFlagsButtonCellRendererComponent
} from "./radioactive-flags-button-cell-renderer/radioactive-flags-button-cell-renderer.component";
import {
  AdditionalInfosButtonCellRendererComponent
} from "./additional-infos-button-cell-renderer/additional-infos-button-cell-renderer.component";
import {PackageDetailDangerousGoods} from "../../../models/dangerous-goods/dangerous-goods.model";

@Component({
  selector: 'app-dangerous-goods',
  templateUrl: './dangerous-goods.component.html',
  styleUrls: ['./dangerous-goods.component.scss']
})
export class DangerousGoodsComponent implements OnInit {
  public packageDetailDangerousGoods = {} as PackageDetailDangerousGoods;

  public gridApi: GridApi;
  public gridOptions: GridOptions;
  private gridColumnApi: any;
  public defaultColDef;
  public columnDefs: ColDef[];

  @Input() set packageDetail(detail) {
    for (let i = 0; i < 1; i++) {
      this.packageDetailDangerousGoods = detail[i].dangerousGoods[i];
    }
  }

  isCollapsed: boolean = false;
  show: boolean = false;


  constructor() {
    this.gridOptions = {
      columnDefs: this.columnDefs,
      enableCellTextSelection: true
    }

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      filter: true,
    };

    this.columnDefs = [
      {
        headerName: 'UN Number',
        field: 'unNumber'
      },
      {
        headerName: 'Packaging Group',
        field: 'packinggroup'
      },
      {
        headerName: 'ADR Amount',
        field: 'adrAmount'
      },
      {
        headerName: 'Density',
        field: 'density'
      },
      {
        headerName: 'LQ',
        field: 'LQ',
      },
      {
        headerName: 'EQ',
        field: 'EQ'
      },
      {
        headerName: 'Transport Category',
        field: 'transportCategory'
      },
      {
        headerName: 'Placard',
        field: 'placard'
      },
      {
        headerName: 'Radioactive Flags',
        cellRenderer: RadioactiveFlagsButtonCellRendererComponent,
      },
      {
        headerName: 'Additional Flags',
        cellRenderer: AdditionalInfosButtonCellRendererComponent,
      }
    ];
  }

  ngOnInit(): void {

  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setRowData([this.packageDetailDangerousGoods]);
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.show = !this.show
  }

}
