import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Email} from "../../models/email.model";

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  private headers: HttpHeaders = new HttpHeaders();
  url: string = environment.aws_url;
  private storeTickets = [];

  private fileAttachment = new BehaviorSubject(false);
  refreshFileAttachmentObserv = this.fileAttachment.asObservable();

  private ticketTable = new BehaviorSubject(false);
  refreshTicketTableObserv = this.ticketTable.asObservable();

  private loadingTicketTable = new BehaviorSubject(false);
  loadingTicketTableObserv = this.loadingTicketTable.asObservable();

  refreshFileAttachment(refresh: boolean) {
    this.fileAttachment.next(refresh);
  }

  refreshTicketTable(refresh: boolean) {
    this.ticketTable.next(refresh);
  }

  setLoadingTicketTable(loading: boolean) {
    this.loadingTicketTable.next(loading);
  }

  public storeTicketData(ticketData) {
    this.storeTickets = ticketData;
  }

  public getStoredTicketData() {
    return this.storeTickets;
  }

  private getHttpHeaders(): void {
    this.headers = this.headers.set("Authorization", `Bearer ${localStorage.getItem('id_token')}`);
  }

  constructor(private http: HttpClient) {
  }

  // TICKET
  getTickets(requestParams?: any[], ids?: string[], searchType?: string): Observable<HttpResponse<any>> {
    let queryParam: string = '';

    if (requestParams?.length > 0) {
      requestParams.map((searchParam) => {
        const parameter = searchParam.param
        const value = searchParam.value

        queryParam = `&${parameter}=${value}`.concat(queryParam)
      });
    }

    this.getHttpHeaders();
    return this.http.get<HttpResponse<any>>(this.url + `/tickets?${searchType}=${ids}${queryParam}`, {
      headers: this.headers,
      observe: 'response'
    });
  }

  getTicketById(ticket_id: number): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.get<HttpResponse<any>>(this.url + `/ticket/${ticket_id}`, {
      headers: this.headers,
      observe: 'response'
    });
  }

  createNewTicket(requestBody) {
    this.getHttpHeaders();
    return this.http.post(this.url + '/ticket', requestBody, {headers: this.headers, observe: 'response'});
  }

  editTicket(ticket_id: number, editTicketData): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.patch<HttpResponse<any>>(this.url + `/ticket/${ticket_id}`, editTicketData, {
      headers: this.headers,
      observe: 'response',
      responseType: 'text' as 'json'
    });
  }

  deleteTicket(ticket_id: number): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.delete<HttpResponse<any>>(this.url + `/ticket/${ticket_id}`, {
      headers: this.headers,
      observe: 'response',
      responseType: 'text' as 'json'
    });
  }

  getAllUsernames(): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    const param = {
      group_name: 'all_usernames'
    }
    return this.http.get<HttpResponse<any>>(this.url + '/users', {
      params: param,
      headers: this.headers,
      observe: 'response'
    });
  }

  // FILE ATTACHMENT
  getTicketFileAttachments(ticket_id: number): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.get<HttpResponse<any>>(this.url + `/ticket/${ticket_id}/attachment`, {
      headers: this.headers,
      observe: 'response'
    });
  }

  createTicketFileAttachment(uploadFile, ticket_id, requestParam) {
    let queryParam: string = '';
    this.getHttpHeaders();

    if (requestParam?.length > 0) {
      requestParam.map((upload) => {
        const parameter = upload.param
        const value = upload.value

        queryParam = `&${parameter}=${value}`.concat(queryParam)
      });
    }
    return this.http.post(this.url + `/ticket/${ticket_id}/attachment?ticket_id=${ticket_id}${queryParam}`, uploadFile, {
      headers: this.headers,
      observe: 'response'
    });
  }

  removeTicketFileAttachment(ticket_id: number, attachment_id: string): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.delete<HttpResponse<any>>(this.url + `/ticket/${ticket_id}/attachment/${attachment_id}`, {
      headers: this.headers,
      observe: 'response'
    });
  }

  // COMMENTS
  getTicketComments(ticket_id: number): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.get<HttpResponse<any>>(this.url + `/ticket/${ticket_id}/comment`, {
      headers: this.headers,
      observe: 'response'
    });
  }

  createTicketComment(ticket_id: number, comment: string): Observable<any> {
    const requestBody = {
      content: comment,
      ticket_id: ticket_id
    };

    this.getHttpHeaders();
    return this.http.post<string>(this.url + `/ticket/${ticket_id}/comment`, requestBody, {
      headers: this.headers,
      responseType: 'text' as 'json'
    });
  }

  deleteTicketComment(ticket_id: number, comment_id: number): Observable<any> {
    this.getHttpHeaders();
    return this.http.delete<any>(this.url + `/ticket/${ticket_id}/comment/${comment_id}`, {
      headers: this.headers,
      responseType: 'text' as 'json'
    })
  }

  // PROGRESS
  getTicketProgress(ticket_id: number): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.get<HttpResponse<any>>(this.url + `/ticket/${ticket_id}/changelog`, {
      headers: this.headers,
      observe: 'response'
    });
  }

  // E-MAIL
  getEmailByTicketId(ticket_id: number): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.get<HttpResponse<any>>(this.url + `/ticket/${ticket_id}/email`, {
      headers: this.headers,
      observe: 'response'
    });
  }

  createTicketEmail(ticket_id: number, requestBody: Email): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.post<HttpResponse<any>>(this.url + `/ticket/${ticket_id}/email`, requestBody, {
      headers: this.headers,
      observe: 'response'
    });
  }


}
