import {Component, TemplateRef} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserMaintenanceService} from "../../../services/user-maintenance/user-maintenance.service";
import {DialogModalType} from "../../../modules/dialog/models/dialog-config.model";
import {AdditionalInfosComponent} from "../../details/dangerous-goods/additional-infos/additional-infos.component";
import {DialogService} from "../../../modules/dialog/services/dialog.service";
import {AddUserToPartnerModalComponent} from "../add-user-to-partner-modal/add-user-to-partner-modal.component";
import {DialogCloseResult} from "../../../modules/dialog/models/dialog.models";
import {DialogCloseResultType} from "../../../modules/dialog/models/dialog-close-result.enum";


@Component({
  selector: 'app-add-user-to-partner-button-cell-renderer',
  templateUrl: './add-user-to-partner-button-cell-renderer.component.html',
  styleUrls: ['./add-user-to-partner-button-cell-renderer.component.scss']
})
export class AddUserToPartnerButtonCellRendererComponent implements ICellRendererAngularComp {
  params;

  constructor( private dialogService: DialogService) {
  }

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: any): void {
    this.params = params.data;
  }

  onButtonClick(): void {
    const dialogRef = this.dialogService.open(
      {
        modalType: DialogModalType.MODAL,
        width: 700,
        closeOnOutsideClicked: false,
        showTitle: true,
        title: 'Add user to partner',
        data: this.params
      },
      AddUserToPartnerModalComponent)
  }

}
