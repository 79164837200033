
<accordion-group #one class="mt-3 detail-card custom-panel">
  <span class="header-container" accordion-heading>Temperature Chain</span>
  <span class="icon-container" accordion-heading>
    <p *ngIf="one.isOpen"><i class="fa-solid fa-caret-up"></i></p>
    <p *ngIf="!one.isOpen"><i class="fa-solid fa-caret-down"></i></p>
  </span>


  <ag-charts-angular style="height: 100%"  [options]="options"></ag-charts-angular>
</accordion-group>
