import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CognitoService} from 'src/app/services/aws-cognito/cognito.service';
import {PackageSearchService} from "../../services/package-search/package-search.service";
import {TicketService} from "../../services/ticketsystem/ticket.service";


@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  public hasUserMaintenanceRight: boolean = false;

  constructor(private router: Router, private cognito: CognitoService, private packageService: PackageSearchService, private ticketService: TicketService) {

  }

  ngOnInit(): void {
    this.checkUserGroups();
    this.clearStoredData();
  }

  checkUserGroups(): void {
    let loadingInterval = setInterval(() => {
      const user_cognito_groups = sessionStorage.getItem('cognito_groups')?.split(',');
      if (user_cognito_groups != undefined) {
        for (let i = 0; i < user_cognito_groups.length; i++) {
          if (user_cognito_groups[i] == 'Staff') {
            this.hasUserMaintenanceRight = true;
            break;
          } else {
            this.hasUserMaintenanceRight = false;
          }
        }
        clearInterval(loadingInterval);
      }
    }, 500);
  }

  private clearStoredData() {
    this.packageService.storePackages([]);
    this.ticketService.storeTicketData([]);
  }

  isSidebarOpen = false;
  isRightSide = false;
  isIconReversed = false;
  isHeaderHovered = false;
  isPinned = false;


  toggleIconReversed() {
    this.isIconReversed = !this.isIconReversed;
    this.isPinned = this.isIconReversed;


    if (this.isIconReversed) {
      this.isHeaderHovered = true;
    } else {
      this.isHeaderHovered = false;
    }

    if (this.isPinned) {
      this.isSidebarOpen = true;
    }
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  logout() {
    this.cognito.signOut().then(() => {
      this.router.navigate(['']);
    }).catch((error) => {
      alert(error.message)
    })
  }


}

