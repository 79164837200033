<accordion-group #one class="mt-3 custom-panel">
  <span class="header-container" accordion-heading>Delivery Information</span>
  <span class="icon-container" accordion-heading>
    <p *ngIf="one.isOpen"><i class="fa-solid fa-caret-up"></i></p>
    <p *ngIf="!one.isOpen"><i class="fa-solid fa-caret-down"></i></p>
  </span>
  <div class="row">
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <p>Content 1</p>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <p>Content 2</p>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <p>Content 3</p>
    </div>
  </div>
</accordion-group>
