import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AgGridModule } from 'ag-grid-angular';
import { AccordionModule } from "ngx-bootstrap/accordion";
import { HttpClientModule } from '@angular/common/http';
//Components
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './views/default-layout/default-layout.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { PackageSearchComponent } from './views/package-search/package-search.component';
import { LoaderComponent } from './views/loader/loader.component';
import { DetailsComponent } from './views/details/details.component';
import { LoginComponent } from './views/login/login.component';
import { GeneralComponent } from './views/details/general/general.component';
import { DeliveryComponent } from './views/details/delivery/delivery.component';
import { CustomsComponent } from './views/details/customs/customs.component';
import { ShipmentEventsComponent } from './views/details/shipment-events/shipment-events.component';
import { ReferencesComponent } from './views/details/references/references.component';
import { DangerousGoodsComponent } from './views/details/dangerous-goods/dangerous-goods.component';
import { PickupServiceComponent } from './views/details/pickup-service/pickup-service.component';
import { AdressesComponent } from './views/details/adresses/adresses.component';
import { ServiceComponent } from './views/details/service/service.component';
import { MoreDetailsButtonCellRendererComponent } from './views/package-search/more-details-button-cell-renderer/more-details-button-cell-renderer.component';
import {DialogModule} from "./modules/dialog/dialog.module";
import { RadioactiveFlagsComponent } from './views/details/dangerous-goods/radioactive-flags/radioactive-flags.component';
import { AdditionalInfosComponent } from './views/details/dangerous-goods/additional-infos/additional-infos.component';
import { RadioactiveFlagsButtonCellRendererComponent } from './views/details/dangerous-goods/radioactive-flags-button-cell-renderer/radioactive-flags-button-cell-renderer.component';
import { AdditionalInfosButtonCellRendererComponent } from './views/details/dangerous-goods/additional-infos-button-cell-renderer/additional-infos-button-cell-renderer.component';
import {ToastrModule} from "ngx-toastr";
import { ModalModule } from 'ngx-bootstrap/modal';
import { UserMaintenanceComponent } from './views/user-maintenance/user-maintenance.component';
import { AddUserToPartnerButtonCellRendererComponent } from './views/user-maintenance/add-user-to-partner-button-cell-renderer/add-user-to-partner-button-cell-renderer.component';

import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { StatusEventComponent } from './views/details/status-event/status-event.component';

import { TemperatureChainComponent } from './views/details/temperature-chain/temperature-chain.component';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AddUserToPartnerModalComponent } from './views/user-maintenance/add-user-to-partner-modal/add-user-to-partner-modal.component';
import { PackageStatusRenderComponent } from './views/package-search/package-status-render/package-status-render.component';

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    DashboardComponent,
    PackageSearchComponent,
    LoaderComponent,
    DetailsComponent,
    LoginComponent,
    GeneralComponent,
    DeliveryComponent,
    CustomsComponent,
    ShipmentEventsComponent,
    ReferencesComponent,
    DangerousGoodsComponent,
    PickupServiceComponent,
    AdressesComponent,
    ServiceComponent,
    MoreDetailsButtonCellRendererComponent,
    RadioactiveFlagsComponent,
    AdditionalInfosComponent,
    RadioactiveFlagsButtonCellRendererComponent,
    AdditionalInfosButtonCellRendererComponent,
    StatusEventComponent,
    TemperatureChainComponent,
    UserMaintenanceComponent,
    AddUserToPartnerButtonCellRendererComponent,
    AddUserToPartnerModalComponent,
    PackageStatusRenderComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    AccordionModule.forRoot(),
    AgGridModule,
    HttpClientModule,
    DialogModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    MatSlideToggleModule,
    AgChartsAngularModule,
    ReactiveFormsModule

  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
