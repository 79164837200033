import {Injectable} from '@angular/core';
import {Auth} from "aws-amplify";
import {interval, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {

  private subscription: Subscription = new Subscription();

  constructor() {
    this.refreshToken();
  }


  refreshToken() {
    // 600000 --> 10 min
    this.subscription.add(interval(600000).subscribe(() => {
      this.refreshIdToken();
    }));
  }

  stopCallingRefreshToken() {
    localStorage.removeItem('id_token');
    this.subscription.unsubscribe();
  }

  /**
   * Get a new ID-Token every 10 minutes for calling an API-Endpoint
   * A livespan of a Token is 15 minutes!
   *
   * Before an endpoint call is made, the ID token is set to HttpHeaders
   */
  public async refreshIdToken() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = cognitoUser.signInUserSession;
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      sessionStorage.setItem('cognito_groups', session.getIdToken().payload['cognito:groups']);
      sessionStorage.setItem('loggedUserName', session.getIdToken().payload['cognito:username']);
      localStorage.setItem('id_token', session.getIdToken().getJwtToken());
      console.log('Token Updated in LocalStorage')
    });
  }

}
