import {Component, OnInit} from '@angular/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from "ag-grid-community";
import {DialogConfig} from "../../../../modules/dialog/models/dialog-config.model";
import {DialogRef} from "../../../../modules/dialog/dialog-ref";
import {AdditionalInfos} from "../../../../models/dangerous-goods/dangerous-goods.model";

@Component({
  selector: 'app-additional-infos',
  templateUrl: './additional-infos.component.html',
  styleUrls: ['./additional-infos.component.scss']
})
export class AdditionalInfosComponent implements OnInit {

  public gridApi: GridApi;
  public gridOptions: GridOptions;
  private gridColumnApi: any;
  public defaultColDef;
  public columnDefs: ColDef[];

  public additionalInfos: AdditionalInfos[] = [];

  constructor(public dialogConfig: DialogConfig, private dialogRef: DialogRef,) {
    this.additionalInfos = this.dialogConfig.data

    this.gridOptions = {
      columnDefs: this.columnDefs,
      enableCellTextSelection: true
    }

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      filter: true,
      // enablePivot: false,
    };

    this.columnDefs = [
      {
        headerName: 'Additional Discription',
        field: 'additionalDiscription'
      },
      {
        headerName: 'Material Description',
        field: 'materialDescription'
      },
      {
        headerName: 'Language',
        field: 'language'
      },
    ];

  }

  ngOnInit(): void {
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setRowData(this.additionalInfos);
  }

  onRowSelected() {

  }

}
