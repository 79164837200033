import {AbstractControl, ValidatorFn} from "@angular/forms";

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const value: string = control.value;

    // Mindestlänge überprüfen
    if (value && value.length < 8) {
      return { 'minLength': true };
    }

    // Überprüfen, ob mindestens eine Zahl vorhanden ist
    if (!/\d/.test(value)) {
      return { 'noNumber': true };
    }

    // Überprüfen, ob mindestens ein Sonderzeichen vorhanden ist
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return { 'noSpecialCharacter': true };
    }

    return null; // Rückgabe null, wenn das Passwort den Anforderungen entspricht
  };
}
