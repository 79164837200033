<div class="container-fluid">
  <div class="row justify-content-center align-items-center" style="height: 100vh;">
    <div class="col-md-auto">
      <h1 class="header text-center mb-3">EuroConnect</h1>
      <div class="card">
        <div class="card-body">
          <form (keyup.enter)="login()">
            <h1 class="text-left">Log In</h1>
            <p class="text-muted text-left">Sign in to your account</p>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fa-solid fa-user" style="color: #2e628f;"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Username" autocomplete="username" required
                [(ngModel)]="user.username" name='username'>
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fa-solid fa-lock" style="color: #2e628f;"></i></span>
              </div>
              <input [type]="user.showPassword ? 'text' : 'password'"  class="form-control" placeholder="Password" autocomplete="current-password"
                required [(ngModel)]="user.password" name='password'>
                <button type="button" class="btn-secondary" (click)="user.showPassword = !user.showPassword">
                  <i class="fa-solid" [ngClass]="{'fa-eye': !user.showPassword, 'fa-eye-slash': user.showPassword}"></i>
                </button>
            </div>
           <!-- forget password -->
           <p class="text-muted text-left">
            <a href="javascript:void(0)" #autoOpenModalBtn (click)="openModal(resetPasswordModal)">Forget Password</a>
          </p>

           <!-- login button -->
            <div class="row">
              <div class="col-lg-12 col-xl-5">
                <button id="loginId" type="button" class="btn btn-primary btn-block" (click)="login()">LOGIN</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Forget Password Model -->

<ng-template #resetPasswordModal #autoOpenModalBtn>
  <div class="modal-header">
    <h2 *ngIf="step === 1"class="modal-title">Forgot your password? </h2>
    <h2 *ngIf="step === 3"class="modal-title">Change your password</h2>
    <button type="button" class="close" aria-label="Close" (click)="closeResetPasswordModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  
  <div class="modal-body">
    <!-- Step 1: Enter username -->
    <div *ngIf="step === 1">
     <p class="subtitle">Enter your username below and we will send a code to reset your password</p>
      <input type="text" class="form-control" [(ngModel)]="usernameForForgetPassword" >
      <button class="btn btn-primary mt-3" style="width: 200px;" (click)="userSubmit()">Reset my password</button>
    </div>
    <!-- Step 2: Enter code, new password, re-confirm password -->
    <div *ngIf="step === 2">
      <p class="subtitle">We have sent a password reset code by email.Enter it below to reset your password</p>
      <label class="m-2">Code</label>
      <input type="text" class="form-control"  [(ngModel)]="code" autocomplete="new-code">
      
      <label class="m-2">Enter new Password</label>
      <input type="password" class="form-control"  [(ngModel)]="newPassword" autocomplete="new-password">
      
      <label class="m-2">Enter new password again</label>
      <input type="password" class="form-control" [(ngModel)]="confirmPassword" autocomplete="confirm-password">
      
      <button class="btn btn-primary mt-3" style="width: 200px;" (click)="changePassword()">Change Password</button>
    </div>
     <!-- Step 3: new password, re-confirm password -->
    <div *ngIf="step === 3">
      <p class="subtitle">Please enter your new password below.</p>

      <label class="m-2">Name</label>
      <input type="text" class="form-control" [(ngModel)]="name" autocomplete="name">

      <label class="m-2">Enter new Password</label>
      <input type="password" class="form-control" [(ngModel)]="newPass" autocomplete="new-password"/>

      <label class="m-2">Enter new Password again</label>
      <input type="password" class="form-control" [(ngModel)]="confirmPass" autocomplete="confirm-password"/>
      
      <button class="btn btn-primary mt-3" style="width: 200px;" (click)="changePasswordNewUser()">Change Password</button>
    </div>
  </div>
</ng-template>
