<div class="content-wrapper">
  {{ dialogConfig.data.content }}
</div>
<div class="text-right">
  <button type="button" class="btn btn-primary mr-1" (click)="closeOk()">
    Ok
  </button>
  <button type="button" class="btn btn-primary" (click)="closeCancel()">
    Abbrechen
  </button>
</div>
