import {Component, OnInit} from '@angular/core';
import {DialogConfig} from "../../../../modules/dialog/models/dialog-config.model";
import {ColDef, GridApi, GridOptions, GridReadyEvent} from "ag-grid-community";
import {RadioactiveFlags} from "../../../../models/dangerous-goods/dangerous-goods.model";

@Component({
  selector: 'app-radioactive-flags',
  templateUrl: './radioactive-flags.component.html',
  styleUrls: ['./radioactive-flags.component.scss']
})
export class RadioactiveFlagsComponent implements OnInit{

  public gridApi: GridApi;
  public gridOptions: GridOptions;
  private gridColumnApi: any;
  public defaultColDef;
  public columnDefs: ColDef[];

  public radioactiveFlags: RadioactiveFlags[] = [];
  constructor(public dialogConfig: DialogConfig,) {
    this.radioactiveFlags = this.dialogConfig.data

    this.gridOptions = {
      columnDefs: this.columnDefs,
      enableCellTextSelection: true
    }

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      filter: true,
      // enablePivot: false,
    };

    this.columnDefs = [
      {
        headerName: 'Authority Cert. 1',
        field: 'authorityCertificate1'
      },
      {
        headerName: 'Authority Cert. 2',
        field: 'authorityCertificate2'
      },
      {
        headerName: 'Form',
        field: 'form'
      },
      {
        headerName: 'Max Activity',
        field: 'maxActivity'
      },
      {
        headerName: 'Measurement',
        field: 'measurement',
      },
      {
        headerName: 'Package Category',
        field: 'packageCategory'
      },
      {
        headerName: 'Radionuclide',
        field: 'radionuclide'
      },
      {
        headerName: 'Transport Index',
        field: 'transportIndex'
      },
    ];

  }

  ngOnInit(): void {
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setRowData(this.radioactiveFlags);
  }

  onRowSelected() {

  }
}
