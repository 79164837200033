<div class="container mt-3" *ngIf="!isLoading">
  <h3 class="ml-6">Details</h3>
</div>

<div *ngIf="!isLoading">
    <ng-container>
      <accordion>
        <app-general [packageDetail]="searchPackageDetail"></app-general>
      </accordion>
    </ng-container>

    <ng-container>
      <accordion>
       <app-references [packageDetail]="searchPackageDetail"></app-references>
      </accordion>
    </ng-container>

    <ng-container>
      <accordion>
       <app-service [packageDetail]="searchPackageDetail"></app-service>
      </accordion>
    </ng-container>

    <ng-container>
      <accordion>
        <div [hidden]="disableTab">
       <app-pickup-service [packageDetail]="searchPackageDetail"></app-pickup-service>
        </div>
      </accordion>
    </ng-container>

    <ng-container>
      <accordion>
       <app-adresses [packageDetail]="searchPackageDetail"></app-adresses>
      </accordion>
    </ng-container>

    <!-- <ng-container>
      <accordion>
        <app-delivery></app-delivery>
      </accordion>
    </ng-container> -->

    <ng-container>
      <accordion>
        <app-customs [packageDetail]="searchPackageDetail"></app-customs>
      </accordion>
    </ng-container>

    <ng-container>
      <accordion>
        <app-shipment-events
          (sendPackageReferenceId)="refreshPackageDetailView($event)"
          [packageDetail]="searchPackageDetail">
        </app-shipment-events>
      </accordion>
    </ng-container>

  <ng-container>
    <accordion>
      <app-status-event [packageDetail]="searchPackageDetail"></app-status-event>
    </accordion>
  </ng-container>

    <ng-container>
      <accordion>
        <app-dangerous-goods [packageDetail]="searchPackageDetail"></app-dangerous-goods>
      </accordion>
    </ng-container>

    <ng-container>
      <accordion>
        <app-temperature-chain [tempPackageId]='packageId$'></app-temperature-chain>
      </accordion>
    </ng-container>
</div>

<app-loader *ngIf="isLoading"></app-loader>
