import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { CognitoService } from "../services/aws-cognito/cognito.service";

@Injectable({
  providedIn: 'root'
})

export class AuthGuard {

  constructor(private cognitoService: CognitoService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (await this.cognitoService.isAuthenticated()) {
      return true;
    } else {
      alert('No Access');
      this.router.navigate(['']);
      return false;
    }
  }
}
