import {Component, Input, OnInit} from '@angular/core';
import {PackageDetailGeneralInformation} from "../../../models/package-detail-general/package-detail-general.modal";

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  isCollapsed: boolean = false;
  show: boolean = false;
  public generalInformation: PackageDetailGeneralInformation = {
    packageId: '',
    shipmentDate: '',
    shipmentType: '',
    pickupPartner: '',
    deliveryPartner: '',
    weight: 0
  };

  @Input() set packageDetail(detail) {
    // TODO: Hier kommt es ein Array mit zwei Objekten. Eigentlich sollte nur ein Objekt existieren oder ?
    for (let i = 0; i < 1; i++) {
      this.generalInformation.deliveryPartner = detail[i].deliveryPartnerId;
      this.generalInformation.pickupPartner = detail[i].pickupPartnerId;
      this.generalInformation.packageId = detail[i].packageID;
      this.generalInformation.shipmentType = detail[i].shipmentType;
      this.generalInformation.shipmentDate = detail[i].shippingDate;
      this.generalInformation.weight = detail[i].weight;
    }
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.show = !this.show
  }
}
