import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {PackageSearchService} from "../../services/package-search/package-search.service";
import {ToastrService} from "ngx-toastr";
import {ToasterPosition} from "../../models/toastr";
import {PackageDetailShipmentEvent} from "../../services/shipment-event/shipment-event.model";
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  public isLoading: boolean = true;
  public searchPackageDetail;
  public disableTab: boolean;
  public packageId$: Observable<any>;


  constructor(private router: Router, private api: PackageSearchService, private toastrService: ToastrService) {
    const packageID = this.router.getCurrentNavigation()?.extras?.state['packageID'];
    this.packageId$ = of(packageID);
    const packageType = this.router.getCurrentNavigation()?.extras?.state['packageType'];

    const param = [{
      param: 'packageID',
      value: packageID
    }];

    this.getPackageDetail(param, packageType);
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 1000);
  }

  refreshPackageDetailView(event: PackageDetailShipmentEvent) {
    const param = [{
      param: 'packageID',
      value: event.referencedPackageID
    }];

    this.getPackageDetail(param, event.packageType);
  }

  getPackageDetail(param, packageType) {
    this.api.getPackages(param, packageType).subscribe((resp) => {
      if (resp.status == 200) {
        this.toastrService.success('Daten wurden geladen')
        this.searchPackageDetail = resp.body;
        this.isLoading = false;

        this.searchPackageDetail.map((detail) => {
          if (detail.shipmentType) {
            this.disableTab = detail.shipmentType !== 'PS';
          }
        });
      }
    });
  }


}
