<div class="sidebar-container" [class.right-side]="isRightSide" [class.pinned]="isPinned" (mouseenter)="isSidebarOpen = true; isHeaderHovered = true" (mouseleave)="isSidebarOpen = false; isHeaderHovered = false" [class.hovered]="isSidebarOpen">


  <!-- Header -->
<div class="header" [class.hovered]="isHeaderHovered || isPinned" (mouseenter)="isSidebarOpen = false; isHeaderHovered = false" >

  <div class="header-icon" (click)="toggleSidebar(); toggleIconReversed()" (mouseenter)="isSidebarOpen = true; isHeaderHovered = true">
    <img src="assets/img/icon-sidebar-onoff.svg" alt="sidebar-icon" style="width: 25px" [ngClass]="{ hidden: !isSidebarOpen, reversed: isIconReversed, visible : isIconReversed, pinned: isPinned }"/>
  </div>

   <div class="header-content" >
      <h2>EuroConnect</h2>
    </div>

    <div class="right-icons" (mouseenter)="isSidebarOpen = false; isHeaderHovered = false">
<!--      <div class="icons-content">-->
<!--        <img src="assets/img/Icon-info-circle.svg" alt="Info-icon" style="width: 25px" title="Info" />-->
<!--      </div>-->
      <div class="icons-content">
        <img src="assets/img/icon-logout.svg" alt="Logout-icon" style="width: 25px" title="Logout" (click)="logout()"/>
      </div>
   </div>
</div>


  <!-- sidebar -->
  <div class="sidebar" [class.open]="isSidebarOpen || isPinned" >
      <div class="items">
        <div class="item">
          <div class="item-content"  routerLink="/dashboard">
            <img src="assets/img/icon-dashboard.svg" alt="dashboard-icon" width="25px" />
              <span *ngIf="isSidebarOpen || isPinned">Dashboard</span>
          </div>
        </div>
        <div class="item">
          <div class="item-content" routerLink="/package-search">
            <img src="assets/img/icon-search.svg" alt="search-icon" width="25px"/>
              <span *ngIf="isSidebarOpen || isPinned">Package Search</span>
          </div>
        </div>
        <div class="item">
          <div class="item-content" routerLink="/ticketsystem">
            <img src="assets/img/icon-ticket.svg" alt="search-icon" width="25px"/>
            <span *ngIf="isSidebarOpen || isPinned">Ticketsystem</span>
          </div>
        </div>
        <div *ngIf="hasUserMaintenanceRight" class="item">
          <div class="item-content" routerLink="/user-maintenance">
            <img src="assets/img/icon-user-maintenance.svg" alt="user-icon" width="25px"/>
            <span *ngIf="isSidebarOpen || isPinned">User Maintenance</span>
          </div>
        </div>
      </div>
  </div>
</div>

<div class="app-body">
  <main class="main" [ngClass]="isPinned ? 'swipe-container' : 'content-container'">
    <router-outlet></router-outlet>
  </main>
</div>
