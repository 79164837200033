<accordion-group #one class="mt-3 custom-panel">
  <span class="header-container" accordion-heading>Customs Information</span>
  <span class="icon-container" accordion-heading>
    <p *ngIf="one.isOpen"><i class="fa-solid fa-caret-up"></i></p>
    <p *ngIf="!one.isOpen"><i class="fa-solid fa-caret-down"></i></p>
  </span>
  <div class="row">
    <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12">
      <h6>Incoterm</h6>
      <p>{{packageDetailCustomsInformation.incoterm}}</p>
    </div>
    <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12">
      <h6>Declaration</h6>
      <p>{{packageDetailCustomsInformation.declaration}}</p>
    </div>
    <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12">
      <h6>Duty Code</h6>
      <p>{{packageDetailCustomsInformation.dutyCode}}</p>
    </div>
    <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12">
      <h6>Value & Currency</h6>
      <p>€ {{packageDetailCustomsInformation.value}}</p>
    </div>
    <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12">
      <h6>Mark</h6>
      <p>{{packageDetailCustomsInformation.mark}}</p>
    </div>
    <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12">
      <h6>Number</h6>
      <p>{{packageDetailCustomsInformation.number}}</p>
    </div>
  </div>
</accordion-group>
