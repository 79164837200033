import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {PackageDetailShipmentEvent} from "../../../services/shipment-event/shipment-event.model";

@Component({
  selector: 'app-shipment-events',
  templateUrl: './shipment-events.component.html',
  styleUrls: ['./shipment-events.component.scss']
})
export class ShipmentEventsComponent implements OnInit {

  @Output() sendPackageReferenceId: EventEmitter<any> = new EventEmitter<any>();

  public packageDetailShipmentEvent = {} as PackageDetailShipmentEvent;
  public shipmentEvents: PackageDetailShipmentEvent[] = [];

  @Input() set packageDetail(details) {
    details.map((detail) => {
      const shipmentEvent: PackageDetailShipmentEvent = {
        referencedPackageID: detail.referencedPackageID,
        shipmentType: detail.shipmentType,
        packageType: detail.packageType
      }
      this.shipmentEvents.push(shipmentEvent);
    });
  }

  public gridApi: GridApi;
  public gridOptions: GridOptions;
  private gridColumnApi: any;
  public defaultColDef;
  public columnDefs: ColDef[];

  constructor() {

    this.gridOptions = {
      columnDefs: this.columnDefs,
      enableCellTextSelection: true
    }

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      filter: true,
      // enablePivot: false,
    };

    this.columnDefs = [
      {
        headerName: 'Reference Package ID',
        field: 'referencedPackageID'
      },
      {
        headerName: 'Shipment Type',
        field: 'shipmentType'
      },
      {
        headerName: 'Shipment Date',
        field: 'shipmentDate'
      },
    ];
  }

  ngOnInit(): void {

  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setRowData(this.shipmentEvents);
  }

  rowDoubleClicked(event: any) {
    const data = {
      referencedPackageID: event.data.referencedPackageID,
      packageType: event.data.packageType
    }

    this.sendPackageReferenceId.emit(data);
  }
}
