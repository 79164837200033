import {Component, Input, OnInit} from '@angular/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {PackageDetailAddresses} from "../../../models/addresses/addresses.model";


@Component({
  selector: 'app-adresses',
  templateUrl: './adresses.component.html',
  styleUrls: ['./adresses.component.scss']
})
export class AdressesComponent implements OnInit {

  public gridApi: GridApi;
  public gridOptions: GridOptions;
  private gridColumnApi: any;
  public selectedRow;
  public defaultColDef;
  public columnDefs: ColDef[];

  public packageDetailAddresses: PackageDetailAddresses[] = [{
    addressType: '',
    city: '',
    conactMail: '',
    contactName: '',
    name: '',
    country: '',
    contactPhone: '',
    number: '',
    street: '',
    zip: ''
  }];

  @Input() set packageDetail(detail) {
    for (let i = 0; i < 1; i++) {
      this.packageDetailAddresses = detail[i].addresses;
    }
  }

  constructor() {

    this.gridOptions = {
      columnDefs: this.columnDefs,
      enableCellTextSelection: true
    }

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      filter: true,
      // enablePivot: false,
    };

    this.columnDefs = [
      {
        headerName: 'Address Type',
        field: 'addressType'
      },
      {
        headerName: 'Name',
        field: 'name'
      },
      {
        headerName: 'Street & Number',
        field: 'streetandnumber',
        valueGetter: (params) => params.data.street + " " + params.data.number
      },
      {
        headerName: 'City',
        field: 'city'
      },
      {
        headerName: 'ZipCode',
        field: 'zip'
      },
      {
        headerName: 'Country',
        field: 'country'
      },
      {
        headerName: 'Contact Name',
        field: 'contactName'
      },
      {
        headerName: 'Contact Phone',
        field: 'contactPhone'
      },
      {
        headerName: 'Contact Mail',
        field: 'contactMail'
      },
    ];

  }

  ngOnInit(): void {
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setRowData(this.packageDetailAddresses);

  }

  onRowSelected() {

  }
}
