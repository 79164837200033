import {Component, OnInit} from '@angular/core';
import {Auth} from "aws-amplify";
import {RefreshTokenService} from "./services/refreshToken/refresh-token.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Euroconnect';

  constructor(private refreshTokenService: RefreshTokenService) {
    this.refreshTokenService.refreshIdToken();
  }

  ngOnInit(): void {

  }

}
