import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit{


  isCollapsed: boolean = false;
  show: boolean = false;


 constructor() {}

 ngOnInit(): void {
     
 }

 toggleCollapse(): void {
   this.isCollapsed = !this.isCollapsed;
   this.show = !this.show
 }

}