import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from '../../../environments/environment';
import { IUser } from '../../interface/user';
import { BehaviorSubject, from } from 'rxjs';
import {RefreshTokenService} from "../refreshToken/refresh-token.service";
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class CognitoService {


  private authenticationSubject: BehaviorSubject<any>;
  private isFirstLogin: boolean = false;
  private user = new BehaviorSubject<CognitoUser | null>(null);

  constructor(private refreshTokenService: RefreshTokenService, private router: Router) {
    Amplify.configure({
      Auth: (environment as any).cognito
    });
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
   }

  public signIn(user: IUser): Promise<any> {
    return Auth.signIn(user.username, user.password)
      .then((authData) => {
        if (authData.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.isFirstLogin = true;
          // You might want to store some additional information about the user here.
          this.authenticationSubject.next(true);
          this.user.next(authData);
        } else {
          this.isFirstLogin = false;
          this.authenticationSubject.next(true);
          this.user.next(authData);
          this.refreshTokenService.refreshIdToken();
        }
      })
      .catch((error) => {
        console.error('Error during sign in:', error);
        throw error;
      });
  }



  public isFirstTimeLogin(): boolean {
    return this.isFirstLogin;
  }

  public getUser(): Promise<any>{
    return Auth.currentUserInfo();
  }

  public isAuthenticated(): Promise<boolean> {
    if(this.authenticationSubject.value){
      return Promise.resolve(true)
    }else{
      return this.getUser().then((user:any)=>{
        if(user){
          return true;
        }else{
          return false;
        }
      }).catch(()=>{
        return false
      })
    }
  }

  public signOut(): Promise<any> {
    // localStorage.removeItem('id_token');
    this.refreshTokenService.stopCallingRefreshToken();
    return Auth.signOut().then(() => {
      this.authenticationSubject.next(false);
    });
  }


  public changePassword(newPassword: string, name: string): Promise<any> {
    const user = this.user.getValue();
    return Auth.completeNewPassword(user, newPassword, {name});
  }


  public forgetPassword(username: string): Promise<any> {
    return Auth.forgotPassword(username)
      .then(() => {

        // Password reset initiated successfully
        console.log('Password reset initiated successfully. Check your email for a verification code.');
      })
      .catch((error) => {
        // Handle the error
        console.error('Error initiating password reset:', error);
        throw error;
      });
  }

  public forgetPasswordSubmit(user : IUser): Promise<any>{
    return Auth.forgotPasswordSubmit(user.username, user.code, user.password)
  }

}
