import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Partner} from "../../models/partner.model";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PackageSearchService {

  headers: HttpHeaders = new HttpHeaders();

  public storedPackages = [];

  constructor(private http: HttpClient) {
  }

  url: string = environment.aws_url;

  private getHttpHeaders(): void {
    this.headers = this.headers.set("Authorization", `Bearer ${localStorage.getItem('id_token')}`);
  }

  public storePackages(packages) {
    this.storedPackages = packages;
  }

  public getStoredPackages() {
    return this.storedPackages;
  }

  public getPackages(requestParams?: any[], packageType?: string): Observable<HttpResponse<any>> {
    let queryParam: string = '';

    if (requestParams?.length > 0) {
      requestParams.map((searchParam) => {
        const parameter = searchParam.param
        const value = searchParam.value

        queryParam = `&${parameter}=${value}`.concat(queryParam)
      });
    }
    this.getHttpHeaders();
    return this.http.get<HttpResponse<any>>(this.url + `/packages?packageType=${packageType}${queryParam}`, {headers: this.headers, observe: 'response'})
  }

  public getCountries(): Observable<HttpResponse<any>> {
    this.getHttpHeaders();
    return this.http.get<HttpResponse<any>>(this.url + '/countries', {headers: this.headers});
  }

  public getPartners(): Observable<Partner[]> {
    this.getHttpHeaders();
    return this.http.get<Partner[]>(this.url + '/partner', {headers: this.headers});
  }

  //temperature
  public getTempData(packageId?: string): Observable<any> {
    this.getHttpHeaders();
    return this.http.get<any>(this.url + `/temp-data?package_id=${packageId}`, {headers: this.headers});
  }

  public getPackageEvents(packageId: number): Observable<HttpResponse<any>> {
    this.getHttpHeaders();

    const param = {
      package_id: packageId
    }

    return this.http.get<HttpResponse<any>>(this.url + '/event', {params: param, headers: this.headers, observe: 'response'});
  }


}
