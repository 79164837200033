<accordion-group #one [isOpen]="true" class="mt-3 custom-panel">
  <span class="header-container" accordion-heading>General Information</span>
  <span class="icon-container" accordion-heading>
    <p *ngIf="one.isOpen"><i class="fa-solid fa-caret-up"></i></p>
    <p *ngIf="!one.isOpen"><i class="fa-solid fa-caret-down"></i></p>
  </span>
  <div class="row">
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
      <span>{{generalInformation.packageId}}</span>
      <img src="assets/img/Express_Palette.svg" alt="palette-icon" width="30px" title="Palette"/>
      <p class="weight">{{generalInformation.weight }} Kg</p>
      <!-- <p class="ambient"> Ambient 25°C-35°C </p> -->
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
      <h6 style="display: inline-block; margin-right: 5px;">Pickup Partner</h6>
      <img src="assets/img/icon-delivery-info-pickup.svg" alt="pickup-partner" width="30px" class="pickup"/>
      <span class="inline">
        <p class="item">Name: {{generalInformation.pickupPartner}}</p>
      </span>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
      <h6 style="display: inline-block; margin-right: 5px;">Delivery Partner</h6>
      <img src="assets/img/icon-delivery-info-pickup.svg" alt="delivery-partner" width="30px" class="delivery"/>
      <span class="inline">
        <p class="item">Name: {{generalInformation.deliveryPartner}}</p>
      </span>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
      <h6 style="display: inline-block; margin-right: 5px;">Shipment</h6>
      <i class="fa-solid fa-file-lines fa-lg"></i>
      <span class="inline">
        <p class="item">Type: {{generalInformation.shipmentType}}</p>
        <p class="item">Date: {{generalInformation.shipmentDate}}</p>
      </span>
    </div>
  </div>
</accordion-group>
