import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {DialogModalType} from "../../../../modules/dialog/models/dialog-config.model";
import {RadioactiveFlagsComponent} from "../radioactive-flags/radioactive-flags.component";
import {DialogService} from "../../../../modules/dialog/services/dialog.service";

@Component({
  selector: 'app-radioactive-flags-button-cell-renderer',
  templateUrl: './radioactive-flags-button-cell-renderer.component.html',
  styleUrls: ['./radioactive-flags-button-cell-renderer.component.scss']
})
export class RadioactiveFlagsButtonCellRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: ICellRendererParams): void {
    this.params = params
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  constructor(private dialogService: DialogService) {
  }

  onButtonClick(): void {
    const dialogRef = this.dialogService.open(
      {
        modalType: DialogModalType.MODAL,
        width: 1500,
        closeOnOutsideClicked: false,
        showTitle: true,
        title: 'Radioactive Flags',
        data: this.params.data.radioactiveFlags
      },
      RadioactiveFlagsComponent);

  }

}
