<div class="container mt-3">
  <h3 class="ml-6">User Maintenance</h3>
</div>

<div class="container mt-3">
  <button type="button" class="btn btn-primary new-user" (click)="addNewUser(openUserModel)">New User <img src="assets/img/add.svg" alt="add-icon"
                                                                                                           width="30px" class="add"/></button>
</div>

<ng-template #openUserModel>
  <div class="modal-header">
    <h3> Add New User Details </h3>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="createUserForm">
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" class="form-control" id="username" name='username' autocomplete="username" formControlName="username"/>
      </div>

      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control" id="name" name='name' autocomplete="name" formControlName="name"/>
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" name='email' formControlName="email"/>
      </div>

      <div class="form-group">
        <label for="password">Temporary Password</label>
        <input type="password" id="password" class="form-control" formControlName="temporary_password">
        <div *ngIf="createUserForm.get('temporary_password').errors && createUserForm.get('temporary_password').touched">
          <div class="red-color" *ngIf="createUserForm.get('temporary_password').hasError('required')">Passwort ist erforderlich.</div>
          <div class="red-color" *ngIf="createUserForm.get('temporary_password').hasError('minLength')">Passwort muss mindestens 8 Zeichen lang sein.</div>
          <div class="red-color" *ngIf="createUserForm.get('temporary_password').hasError('noNumber')">Passwort muss mindestens eine Zahl enthalten.</div>
          <div class="red-color" *ngIf="createUserForm.get('temporary_password').hasError('noSpecialCharacter')">Passwort muss mindestens ein Sonderzeichen enthalten.</div>
        </div>
      </div>

      <div class="form-group">
        <label>Partner</label>
        <div class="dropdown">
          <button class="btn dropdown-toggle" type="button" id="multiSelectDropdown" data-bs-toggle="dropdown" aria-expanded="false">
            <ng-container *ngIf="selectedItems.length > 0; else defaultText">
              {{ selectedItems.length <= 2 ? selectedItems.join(', ') : selectedItems.slice(0, 4).join(', ') + '...' }}
            </ng-container>
            <ng-template #defaultText>Select Partner</ng-template>
          </button>
          <ul class="dropdown-menu" aria-labelledby="multiSelectDropdown">
            <li *ngFor="let partner of partners">
              <label>
                <input type="checkbox" [value]="partner.GroupName" (change)="handleCB($event)">
                {{ partner.GroupName }}
              </label>
            </li>
          </ul>
        </div>
      </div>

      <button (click)="addUser()" type="submit" class="btn btn-primary create-user">Add</button>
    </form>
  </div>
</ng-template>

<!-- Table for user -->
<div class="table container-fluid">
  <ag-grid-angular
    class="ag-theme-alpine table-size h-100 w-100"
    [gridOptions]="gridOptions"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    (gridReady)="onGridReady($event)"
    [paginationPageSize]="paginationPageSize"
    [paginationNumberFormatter]="paginationNumberFormatter"
  >
  </ag-grid-angular>
</div>
