import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { PackageSearchService } from 'src/app/services/package-search/package-search.service';


@Component({
  selector: 'app-package-status-render',
  templateUrl: './package-status-render.component.html',
  styleUrls: ['./package-status-render.component.scss']
})
export class PackageStatusRenderComponent  implements ICellRendererAngularComp{
  eventCode: string = '';
  eventText: string = '';

  constructor(private packageSearchService : PackageSearchService) {}

  public showTextAndHideButton: boolean = false;
  params: any;
  public packageId: number; 

  agInit(params: any): void {
    this.params = params;
    this.packageId = this.params.data.packageID; 
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  } 


  public onButtonClickAndShowText(): void {   
    this.showTextAndHideButton = true;
    this.packageSearchService.getPackageEvents(this.packageId).subscribe((packageEvent) => {    
      const lastEventIndex = packageEvent.body.length - 1; 
      if (lastEventIndex > 0) {
        this.eventCode = packageEvent.body[lastEventIndex].eventcode;
        this.eventText = this.getEventText(this.eventCode); 
      }else {
        this.eventText = "NONE";
      }
    });
  }

  private getEventText(eventCode: string): string {
    switch (eventCode) {
      case '10':
        return ' - Successful delivery';
      case '11':
        return ' - Deposit by agreement';
      case '12':
        return ' - Pick-Up successful';
      case '13':
        return ' - Self Pick-Up';
      case '20':
        return ' - Loss of goods';
      case '21':
        return ' - Broken goods';
      case '22':
        return ' - Return shipment';
      case '23':
        return ' - Refusal of acceptance';
      case '24':
        return ' - Vacation';
      case '25':
        return ' - Wrong Address';
      case '50':
        return ' - Out for pickup scan';
      case '51':
        return ' - Scan at the inbound gateway';
      case '52':
        return ' - Scan into temperature...';
      case '53':
        return ' - Loading Scan';
      case '54':
        return ' - Scan at the outbound gateway';
      case '55':
        return ' - Out-for-delivery scan';
      case '56':
        return ' - Information on Delivery';
      case '60':
        return ' - Transport Unit Connection';
      default:
        return ' - Unknown Event';
    }
  }

}
