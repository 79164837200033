import {Component, OnInit, ViewChild} from '@angular/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import * as moment from 'moment';
import {PackageSearchService} from 'src/app/services/package-search/package-search.service';
import {
  MoreDetailsButtonCellRendererComponent
} from "./more-details-button-cell-renderer/more-details-button-cell-renderer.component";
import {Partner} from "../../models/partner.model";
import {Observable} from "rxjs";
import {ToastrService} from "ngx-toastr";
import { PackageStatusRenderComponent } from './package-status-render/package-status-render.component';

@Component({
  selector: 'app-package-search',
  templateUrl: './package-search.component.html',
  styleUrls: ['./package-search.component.scss']
})
export class PackageSearchComponent implements OnInit {
  @ViewChild('rangePicker') rangePicker;

  // AG-Grid
  public gridApi: GridApi;
  public gridOptions: GridOptions;
  private gridColumnApi: any;
  public defaultColDef;
  public columnDefs: ColDef[];

  public dropdownPartner$: Observable<Partner[]>;
  public dropdownCountries$: Observable<any>;
  public packageType: string = "C"; // initial Colli
  public selectedShipmentTypeName: string = null;
  private selectedShipmentType: string = null;
  public selectedPickupPartner: string = null;
  private selectedPickupPartnerId: string = null;
  public selectedDeliveryPartner: string = null;
  private selectedDeliveryPartnerId: string = null;
  public selectedCountry: string = null;
  public zipcodeFrom: string;
  public zipcodeTo: string;

  public datePickerValue: Date[];
  private datePickerFrom: string;
  private datePickerTo: string;

  public isPaletteChecked: boolean;
  public isColliChecked: boolean;

  public isLoading: boolean = true;
  public searchTerm: string = '';
  public shipmentType: string = '';

  public disableButton: boolean = false;

  shipmentTypes = [
    {value: 'option1', label: 'Normal'},
    {value: 'option2', label: 'Retour'},
    {value: 'option3', label: 'Pick-up'},
    {value: 'option4', label: 'Pick-up Shipment'}
  ];

  constructor(private api: PackageSearchService, private toastrService: ToastrService) {

    this.gridOptions = {
      columnDefs: this.columnDefs,
      enableCellTextSelection: true
    }

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      filter: true,
      // enablePivot: false,
    };

    this.columnDefs = [
      {
        headerName: 'Partner Number',
        field: 'pickupPartnerId'
      },
      {
        headerName: 'Package Reference',
        field: 'referencedPackageID'
      },
      {
        headerName: 'Shipping Date',
        field: 'shippingDate'
      },
      // {
      //   headerName: 'Delivery Status',
      //   field: 'deliveryStatus'
      // },
      {
        headerName: 'Recipient Address',
        field: 'recipientAddress',
        valueGetter: (params) => this.getRecipientAddress(params.data),
      },
      {
        headerName: 'Pickup Partner',
        field: 'pickupPartnerId'
      },
      {
        headerName: 'Delivery Partner',
        field: 'deliveryPartnerId'
      },
      {
        headerName: 'Customer Identifier/Name',
        field: 'customerIdentifier'
      },
      {
        headerName: 'Package Status',
        cellRenderer: PackageStatusRenderComponent,
      },
      {
        headerName: 'Details',
        cellRenderer: MoreDetailsButtonCellRendererComponent,
      }
    ];

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);

    this.dropdownPartner$ = this.api.getPartners();
    this.dropdownCountries$ = this.api.getCountries();
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi

    this.gridApi.setRowData(this.api.getStoredPackages());
  }

  onSearch() {
    this.disableButton = true;

    let setParameterMap = new Map<string, string>();

    if (this.searchTerm) {
      setParameterMap.set("reference", this.searchTerm);
    }

    if (this.selectedPickupPartnerId) {
      setParameterMap.set("pickupPartnerId", this.selectedPickupPartnerId);
    }

    if (this.selectedDeliveryPartnerId) {
      setParameterMap.set("deliveryPartnerId", this.selectedDeliveryPartnerId);
    }

    if (this.selectedShipmentType) {
      setParameterMap.set("shipmentType", this.selectedShipmentType);
    }

    if (this.selectedCountry) {
      setParameterMap.set('country', this.selectedCountry);
    }

    if (this.zipcodeFrom) {
      setParameterMap.set('zipFrom', this.zipcodeFrom);
    }

    if (this.zipcodeTo) {
      setParameterMap.set('zipTo', this.zipcodeTo);
    }

    if (this.datePickerValue) {
      let parseFrom = moment(this.datePickerValue[0], "YYYY.MM.DD");
      this.datePickerFrom = parseFrom.format("DD.MM.YYYY");

      let parseTo = moment(this.datePickerValue[1], "YYYY.MM.DD");
      this.datePickerTo = parseTo.format("DD.MM.YYYY");

      setParameterMap.set("shippingDateFrom", this.datePickerFrom);
      setParameterMap.set("shippingDateTo", this.datePickerTo);
    }

    this.getPackages(setParameterMap, this.packageType);
    
  }

  public getRecipientAddress(data) {
    let name: string = '';
    let street: string = '';
    let hausnr: string = '';
    let zip: string = '';
    let city: string = '';
    let country: string = '';

    if (data.addresses) {
      data.addresses.forEach((address) => {
        if (address.addressType == "RECIPIENT") {
          name = address.name;
          street = address.street;
          hausnr = address.number;
          zip = address.zip;
          city = address.city;
          country = address.country;
        }
      });
    }
    return `${name} ${street} ${hausnr} ${zip} ${city} ${country}`
  }


  getPackages(searchParameters: Map<string, string>, packageType: string) {
    let paramArray = [];

    for (let [key, value] of searchParameters.entries()) {
      let parameter = {
        param: key,
        value: value
      }
      paramArray.push(parameter);
    }

    this.gridApi.showLoadingOverlay();

    this.api.getPackages(paramArray, packageType).subscribe((res) => {
      if (res.status == 200) {
        let result = res.body.filter((r) => r.packageID);
        this.api.storePackages(result);
        if (this.gridApi) {
          this.gridApi.setRowData(result);
          this.gridApi.hideOverlay();
          this.disableButton = false;
        }
      }
    }, error => {
      this.toastrService.error(error.error, 'Error');
      this.gridApi.hideOverlay();
    });
  };

  bsConfig = {
    showWeekNumbers: false,
    rangeInputFormat: 'DD/MM/YYYY',
    dateInputFormat: 'DD/MM/YYYY',
    maxDate: new Date(),

  };

  // selectedDateRange = [moment().toDate()];

  onButtonClick(params: any) {
    const cell = params.api.getCellForEvent(params.event);
    if (cell) {
      cell.addOrRemoveCssClass('border-hidden'); // Add or remove the CSS class
    }
  }

  openDatepicker(datepicker: any) {
    datepicker.show();
  }

  onRowSelected() {

  }

  onSelectedShipmentType(event) {
    this.selectedShipmentTypeName = event.target.text;
    this.selectedShipmentType = this.setShipmentType(this.selectedShipmentTypeName);
  }

  setShipmentType(selectedShipmentTypeName) {
    switch (selectedShipmentTypeName) {
      case 'Normal':
        return 'N'
      case 'Retour':
        return 'R'
      case 'Pick-up':
        return 'P'
      case 'Pick-up Shipment':
        return 'PS'
      default:
        return null
    }
  }

  onSelectedPickupPartner(event) {
    this.selectedPickupPartner = event.target.text;
    // seperate ID and value
    const regex = /^[0-9]*/;
    const matches = this.selectedPickupPartner.match(regex);
    this.selectedPickupPartnerId = matches[0];
  }

  onSelectedDeliveryPartner(event) {
    this.selectedDeliveryPartner = event.target.text;
    // seperate ID and value
    const regex = /^[0-9]*/;
    const matches = this.selectedDeliveryPartner.match(regex);
    this.selectedDeliveryPartnerId = matches[0];
  }

  checkedInputFields(event: any): void {
    if (event.target.id === "palette") {
      this.isPaletteChecked = event.target.checked;
      this.packageType = "P";

    } else if (event.target.id === "colli") {
      this.isColliChecked = event.target.checked;
      this.packageType = "C";
    }
  }

  resetFilter() {
    this.selectedShipmentType = null;
    this.selectedShipmentTypeName = null;
    this.selectedPickupPartner = null;
    this.selectedPickupPartnerId = null;
    this.selectedDeliveryPartner = null;
    this.selectedDeliveryPartnerId = null;
    this.datePickerValue = null;
    this.datePickerFrom = null;
    this.datePickerTo = null;
    this.isPaletteChecked = false;
    this.isColliChecked = false;
    this.searchTerm = "";
    this.zipcodeFrom = null;
    this.zipcodeTo = null;
  }

  onDateRangePickerShow() {
    // This is a workaround to show previous month
    let prevMonth = new Date();
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    this.rangePicker._datepicker.instance.monthSelectHandler({date: prevMonth});
  }

  onSelectedCountry(event) {
    this.selectedCountry = event.target.text;
  }

  

}
