import {Component, Input, OnInit} from '@angular/core';
import {PackageDetailCustomsInformation} from "../../../models/customs/customs-model";

@Component({
  selector: 'app-customs',
  templateUrl: './customs.component.html',
  styleUrls: ['./customs.component.scss']
})
export class CustomsComponent implements OnInit{
  public packageDetailCustomsInformation: PackageDetailCustomsInformation = {
    currency: '',
    declaration: '',
    dutyCode: 0,
    incoterm: '',
    mark: '',
    number: '',
    value: 0
}

  @Input() set packageDetail(detail) {
    for(let i = 0; i < 1; i++) {
      this.packageDetailCustomsInformation = detail[i].customs;
    }
  }

  isCollapsed: boolean = false;
  show: boolean = false;


 constructor() {}

 ngOnInit(): void {

 }

 toggleCollapse(): void {
   this.isCollapsed = !this.isCollapsed;
   this.show = !this.show
 }
}
