import { Component } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {DialogService} from "../../../../modules/dialog/services/dialog.service";
import {DialogModalType} from "../../../../modules/dialog/models/dialog-config.model";
import {AdditionalInfosComponent} from "../additional-infos/additional-infos.component";

@Component({
  selector: 'app-additional-infos-button-cell-renderer',
  templateUrl: './additional-infos-button-cell-renderer.component.html',
  styleUrls: ['./additional-infos-button-cell-renderer.component.scss']
})
export class AdditionalInfosButtonCellRendererComponent implements ICellRendererAngularComp{
  params: any;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  constructor(private dialogService: DialogService) {
  }

  onButtonClick(): void {
    const dialogRef = this.dialogService.open(
      {
        modalType: DialogModalType.MODAL,
        width: 1500,
        closeOnOutsideClicked: false,
        showTitle: true,
        title: 'Additional Infos',
        data: this.params.data.additionalInfos
      },
      AdditionalInfosComponent);

  }

}
