  <div class="modal-header">
    <h3> Add Partner </h3>
  </div>

  <div class="modal-body">
    <form [formGroup]="addUserToPartnerForm">

      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" class="form-control" id="username" name='username' formControlName="username" readonly />
      </div>

      <select (change)="onSelectedPartner($event)" class="form-select mt-4" aria-label="Default select example">
        <option selected *ngFor="let partner of partners"
                [value]="partner.GroupName">{{partner.GroupName}}</option>
      </select>

      <button [disabled]="!showSubmitButton" (click)="addPartner()" style="float: right" class="btn btn-primary mt-4"> Add Partner</button>

    </form>
  </div>
