<accordion-group #one class="mt-3 custom-panel">
  <span class="header-container" accordion-heading>Services</span>
  <span class="icon-container" accordion-heading>
    <p *ngIf="one.isOpen"><i class="fa-solid fa-caret-up"></i></p>
    <p *ngIf="!one.isOpen"><i class="fa-solid fa-caret-down"></i></p>
  </span>
  <div class="row">
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
      <span>Service Code: {{packageDetailServices.code}}</span>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
      <span>Date: {{packageDetailServices.date}}</span>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
      <span>Email: {{packageDetailServices.email}}</span>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
      <span>SMS: {{packageDetailServices.sms}}</span>
    </div>
  </div>
</accordion-group>
